import {
  all,
  takeLatest,
  takeEvery,
  put,
  delay,
  select,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {httpGet, getResponseBody, httpPost, getSelectedSiteAndRobotId, isRobotInManualMode} from 'utils'
import {addSnackbarMessage} from 'app'
import {SET_SETTINGS_LISTENER, CONFIG_LIST_UPDATED} from 'modules/navigation/constants'
import * as constants from './constants'
import * as actions from './actions'
import {FETCH_SITE_SETTINGS} from '../../../constants'
import {Store} from 'types'
import {SanitizedObject, SanitizedObjects} from '../types'
import {fetchObjectsSuccess} from './actions'
import {getCurrentSettings} from './selectors'

// if someone changes settings - propagate change via SSE
function* fetchObjects() {
  yield takeLatest<AnyAction>([FETCH_SITE_SETTINGS, constants.FETCH_OBJECTS], function* (action) {
    let context = yield getSelectedSiteAndRobotId()
    while (!context.robotId && !context.siteId) {
      yield delay(300)
      context = yield getSelectedSiteAndRobotId()
    }

    const response: Response = yield httpGet(`v1/sanitize/robot/${context.siteId}/${context.robotId}/settings`)
    const {status} = response
    if (status >= 300) {
      yield put(addSnackbarMessage('Error when getting items. Please try again'))
      yield put(actions.fetchObjectsError('request failed'))
    } else {
      const objects = yield getResponseBody(response)
      yield put(actions.fetchObjectsSuccess(objects))
    }
  })
}

function* saveObjectSettings() {
  yield takeEvery<AnyAction>([constants.SAVE_OBJECT_SETTINGS, constants.SET_NAME, constants.SET_HOME_UID], function* (action) {
    const {onSuccess} = action
    let editedObjects: SanitizedObject[] = yield select((state: Store) => state.uv.settings.editedObjects)
    let name = yield select((state: Store) => getCurrentSettings(state)?.name)
    let homeUid: undefined | string
    if (action.name) {
      name = action.name
      editedObjects = []
    }

    if (action.homeUid) {
      homeUid = action.homeUid
    }

    let context = yield getSelectedSiteAndRobotId()
    while (!context.robotId && !context.siteId) {
      yield delay(300)
      context = yield getSelectedSiteAndRobotId()
    }

    function* showError() {
      const isInManualMode = yield isRobotInManualMode()
      const message = isInManualMode ?
        'uv_SanitizingObjects content sanitization object settings save error manual' :
        'uv_SanitizingObjects content sanitization object settings save error'
      yield put(addSnackbarMessage(message))
    }

    const query = new URLSearchParams(window.location.search)
    let configurationUid = query.get('id')
    const request = {
      objects: editedObjects.map(object => ({
        pointId: object.pointId,
        enabled: object.enabled,
        level: object.level,
      })),
      name,
      homeUid,
    }
    if (!configurationUid) {
      configurationUid = yield select((state: Store) => getCurrentSettings(state)?.configurationUid)
    }

    if (!configurationUid) {
      configurationUid = yield select((state: Store) => state.uv.settings.objects[0]?.configurationUid)
    }

    const currentSettings: SanitizedObject[] = yield select((state: Store) => state.uv.settings.objects
      .find(obj => obj.configurationUid === configurationUid)?.objects) || []
    const areAllObjectsDisabled = currentSettings.every(current => {
      const item = editedObjects.find(object => current.pointId === object.pointId) || current
      return !item.enabled
    })

    // do not save all settings disabled
    if (areAllObjectsDisabled) {
      yield put(addSnackbarMessage('Nav_Schedule cannot save empty settings'))
      return
    }

    const response: Response = yield httpPost(
      `v1/sanitize/robot/${context.siteId}/${context.robotId}/settings/${configurationUid}`,
      request)
    const {status} = response
    if (status >= 300) {
      yield showError()
    } else {
      const savedObjects = yield getResponseBody(response)
      if (!savedObjects) {
        yield showError()
      } else {
        yield put(actions.saveObjectSuccess(configurationUid, savedObjects))
        yield put(addSnackbarMessage('uv_SanitizingObjects content sanitization object settings save success'))
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  })
}

function* updateObjectSettings() {
  yield takeLatest<AnyAction>([SET_SETTINGS_LISTENER], function* (action) {
    const {robotId, siteId, settings, configurationUid, name, fullSettings} = action
    const context = yield getSelectedSiteAndRobotId()
    if (context.siteId !== siteId || context.robotId !== robotId) {
      return
    }

    const currentSettings: SanitizedObjects[] = yield select((state: Store) => state.uv.settings.objects)
    const newSettings = currentSettings.map(sanitizedObjectSettings => {
      if (sanitizedObjectSettings.configurationUid === configurationUid) {
        return {
          ...sanitizedObjectSettings,
          name,
          objects: fullSettings?.objects || sanitizedObjectSettings.objects.map(object => {
            const itemFromSettings = settings.rooms.find((setting: {roomId: string}) => setting.roomId === object.pointId)
            return itemFromSettings ? {
              ...object,
              enabled: itemFromSettings.enabled,
            } : object
          }),
        }
      } else {
        return sanitizedObjectSettings
      }
    })

    if (fullSettings && !newSettings.some(setting => setting.configurationUid === configurationUid)) {
      newSettings.push({
        ...fullSettings,
        name,
      })
    }

    yield put(fetchObjectsSuccess(newSettings))
  })
}

function* updateObjectConfig() {
  yield takeLatest<AnyAction>([CONFIG_LIST_UPDATED], function* (action) {
    const {robotId, siteId, configurationUids} = action
    const context = yield getSelectedSiteAndRobotId()
    if (context.siteId !== siteId || context.robotId !== robotId) {
      return
    }
    const currentSettings: SanitizedObjects[] = yield select((state: Store) => state.uv.settings.objects)
    const newSettings = currentSettings
      .filter(sanitizedObjectSettings => configurationUids.includes(sanitizedObjectSettings.configurationUid))

    yield put(fetchObjectsSuccess(newSettings))
  })
}

function* settingsSagas() {
  yield all([
    fetchObjects(),
    saveObjectSettings(),
    updateObjectSettings(),
    updateObjectConfig(),
  ])
}

export default settingsSagas
