import {RobotState, Store} from 'types'
import {getKeyForRobot, hasLimitFindingFailed} from '../utils'

export const getInitializationFailedMessage = (state: Store, join = ', ') => {
  const healthStatus = state.app.selectedRobot?.healthStatus
  const robotState = healthStatus?.robotState
  if (robotState !== RobotState.CRITICAL_ERROR) {
    return ''
  }

  const previousRobotState = healthStatus?.previousRobotState
  const postMessage = healthStatus?.postOutcomeSuccess === false ?
    healthStatus.postOutcomeFailure?.join(join) :
    undefined

  if (!postMessage && previousRobotState !== RobotState.INITIALIZING) {
    return ''
  }

  return postMessage || 'Unknown error'
}

export const getInitializationFailedClientMessage = (state: Store) => {
  const failedMessage = getInitializationFailedMessage(state, ' ')
  if (failedMessage.includes('Motor Power ON before POST 2 was unsuccessful')) {
    return 'Motor Power ON before POST 2 was unsuccessful'
  }

  return undefined
}

export const shouldShowCalibrationIncomplete = (state: Store) => {
  const healthStatus = state.app.selectedRobot?.healthStatus
  const lfFailed = hasLimitFindingFailed(healthStatus?.limitFindingStatus)
  if (!lfFailed) {
    return false
  }

  return healthStatus?.limitFindingOutcomeRerunnable === false
}

export const isCurrentRobotOnline = (state: Store) => {
  const robot = state.app.selectedRobot
  if (!robot) {
    return false
  }
  const robotKey = getKeyForRobot(robot)
  return !state.app.offlineRobots.includes(robotKey)
}

export const getGuiUsecase = (state: Store) => {
  const guiUseCase = state.app.guiUseCase

  return guiUseCase
}
