import moment from 'moment'
import RRule, {ByWeekday, Frequency} from 'rrule'
import {SCHEDULE_REPEAT_OPTIONS} from './constants'
import {ScheduleListElement} from './types'

export const weekdaysOrder = [1, 2, 3, 4, 5, 6, 0] // Sunday last

const executeExpression = (expression: string, rrule: Function, cron: Function) => {
  return expression && expression.includes('RRULE') ?
    rrule(expression) :
    cron(expression)
}

export const getRepeatTypeBasedOnCronExpression = (cronExpression?: string, weeklyInterval?: number) => {
  if (weeklyInterval && weeklyInterval > 1) {
    return 'every_week'
  }

  for (const repeatOption of SCHEDULE_REPEAT_OPTIONS) {
    if (cronExpression && repeatOption.regex && repeatOption.regex.test(cronExpression)) {
      return repeatOption.type
    }
  }

  return 'custom'
}

export const isLastWeekdayOfMonth = (date: Date) => {
  const endOfMonth = moment(date).endOf('month')
  return Math.abs(date.getDate() - endOfMonth.date()) < 7
}

export const getPositionOfWeekdayInMonth = (date: Date) => {
  return Math.ceil(date.getDate() / 7)
}

const getNthWeekdayRRule = (date: Date, nthWeek: number) => {
  const dayOfTheWeek = matchWeekDays(date.getDay())
  switch (dayOfTheWeek) {
    case 0:
      return [RRule.MO.nth(nthWeek)]
    case 1:
      return [RRule.TU.nth(nthWeek)]
    case 2:
      return [RRule.WE.nth(nthWeek)]
    case 3:
      return [RRule.TH.nth(nthWeek)]
    case 4:
      return [RRule.FR.nth(nthWeek)]
    case 5:
      return [RRule.SA.nth(nthWeek)]
    case 6:
      return [RRule.SU.nth(nthWeek)]
    default:
      return [RRule.MO.nth(nthWeek)]
  }
}

const getNthWeekdayFromRRule = (rruleExpression: string) => {
  const options = RRule.parseString(rruleExpression)
  const weekday = options.byweekday
  if (weekday) {
    let wd = ''
    if (Array.isArray(weekday)) {
      wd = weekday[0].toString()
    } else {
      wd = weekday.toString()
    }
    if (wd.startsWith('+')) {
      return parseInt(wd) || -1
    }
  }
  return -1
}

const getMonthFromRRule = (rruleExpression: string) => {
  const options = RRule.parseString(rruleExpression)
  if (Array.isArray(options.bymonth)) {
    return options.bymonth[0] || 1
  }
  return options.bymonth || 1
}

const getIntervalFromCron = (cronExpression: string, weeklyRepetitions: number): number => {
  const partWithInterval = cronExpression.split(' ').find(part => part.includes('/'))
  if (partWithInterval) {
    const interval = parseInt(partWithInterval.split('/')[1], 10)
    return isNaN(interval) ? weeklyRepetitions : interval
  }

  // don't use 0!
  return weeklyRepetitions || 1
}

const getIntervalFromRRule = (rruleExpression: string, weeklyRepetitions: number): number => {
  const options = RRule.parseString(rruleExpression)
  const interval = options.interval
  if (interval === undefined) {
    return weeklyRepetitions || 1
  } else {
    return interval > 0 ? interval : 1
  }
}

export const getIntervalFromExpression = (expression: string, weeklyRepetitions: number): number => {
  return expression && expression.includes('RRULE') ?
    getIntervalFromRRule(expression, weeklyRepetitions) :
    getIntervalFromCron(expression, weeklyRepetitions)
}

export const getRadioSelectionFromCron = (cronExpression: string, defaultValue: string): string => {
  // day is the last part of cron expression
  const weekday = cronExpression.split(' ').reverse()[0]
  if (weekday.endsWith('L')) {
    return 'last'
  } else if (weekday.includes('#')) {
    return 'nth'
  }

  return defaultValue
}

export const getRadioSelectionFromRRule = (rruleExpression: string, defaultValue: string): string => {
  const options = RRule.parseString(rruleExpression)
  if (options.bysetpos === -1) {
    return 'last'
  } else if (options.byweekday) {
    if (options.byweekday.toString().includes('+')) {
      return 'nth'
    }
  }
  return defaultValue
}

export const getRadioSelectionFromExpression = (expression: string, defaultValue: string): string => {
  return expression && expression.includes('RRULE') ?
    getRadioSelectionFromRRule(expression, defaultValue) :
    getRadioSelectionFromCron(expression, defaultValue)
}

const getInitialCronIntervalType = (cronExpression: string) => {
  if (!cronExpression) {
    return 'hour'
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [second, minute, hour, day, month, weekday] = cronExpression
    .split(' ')
    .map(part => part.endsWith('/1') ? '*' : part)
  if (hour.includes('/') || hour === '*') {
    return 'hour'
  } else if (
    ((day.startsWith('*') || day.includes('/')) && month === '*' && weekday === '*') ||
    weekday === '0-6' ||
    weekday.split(',').length === 7
  ) {
    return 'day'
  } else if (day === '*' && month === '*' && !weekday.includes('#') && !weekday.toUpperCase().endsWith('L')) {
    return 'week'
  } else if (month.startsWith('*')) {
    return 'month'
  } else {
    return 'year'
  }
}

const getInitialRRuleIntervalType = (rruleExpression: string) => {
  const options = RRule.parseString(rruleExpression)
  switch (options.freq) {
    case Frequency.HOURLY: return 'hour'
    case Frequency.DAILY: return 'day'
    case Frequency.WEEKLY: return 'week'
    case Frequency.MONTHLY: return 'month'
    default:
      return 'year'
  }
}

export const getInitialIntervalType = (expression: string) => {
  return executeExpression(expression, getInitialRRuleIntervalType, getInitialCronIntervalType)
}


const getDatesFromCron = (cronExpression: string): number[] => {
  // day is the last part of cron expression
  let dayPart = cronExpression.split(' ').reverse()[0]
  if (dayPart.toUpperCase().endsWith('L')) {
    dayPart = dayPart.substring(0, dayPart.length - 1)
  } else if (dayPart.includes('#')) {
    dayPart = dayPart.split('#')[0]
  }

  if (dayPart === '*') {
    return [0, 1, 2, 3, 4, 5, 6]
  }

  // we want to check if it's a single day for example "1" == 1
  if (dayPart === String(parseInt(dayPart, 10))) {
    return [parseInt(dayPart, 10)]
  }

  // range, for example 1-5
  if (dayPart.includes('-')) {
    const [from, to] = dayPart.split('-')
    const min = Math.min(parseInt(from, 10), parseInt(to, 10))
    const max = Math.max(parseInt(from, 10), parseInt(to, 10))
    const result: number[] = []
    for (let i = min; i <= max; i++) {
      result.push(i)
    }

    return result
  }

  // days separated by comma, for example 1,3,4
  if (dayPart.includes(',')) {
    return dayPart.split(',').map(num => parseInt(num, 10))
  }

  return []
}

export const getWeekDayIndex = (weekDay: ByWeekday): number => {
  const week: string[] = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
  let wDay = weekDay.toString()
  if (wDay.startsWith('+') && wDay.length === 4) {
    wDay = wDay.substring(2, 4)
  }
  return week.indexOf(wDay)
}

const getDatesFromRRule = (rruleExpression: string): number[] => {
  const options = RRule.parseString(rruleExpression)
  const days = options.byweekday
  if (days) {
    const tmp: number[] = []
    if (Array.isArray(days)) {
      for (const day of days) {
        tmp.push(getWeekDayIndex(day))
      }
    } else {
      tmp.push(getWeekDayIndex(days))
    }
    return tmp.sort()
  } else if (options.dtstart) {
    return [options.dtstart.getDay()]
  }
  return []
}

const getDayInMonthFromRRule = (rruleExpression: string) => {
  const options = RRule.parseString(rruleExpression)
  const mDay = options.bymonthday
  if (mDay) {
    if (Array.isArray(mDay)) {
      return mDay[0]
    }
    return mDay
  }
  const wDay = options.byweekday
  if (wDay) {
    if (Array.isArray(wDay)) {
      return getWeekDayIndex(wDay[0])
    }
    return getWeekDayIndex(wDay)
  }
  return 1
}

export const getDatesFromExpression = (expression: string): number[] => {
  return executeExpression(expression, getDatesFromRRule, getDatesFromCron)
}

export function setTimeInCron(cronExpression: string, utcHours: number, utcMinutes: number) {
  return cronExpression
    .split(' ')
    .map((cronPart, index) => {
      const isMinutesPart = index === 1
      const isHoursPart = index === 2
      if (isMinutesPart || isHoursPart) {
        const value = isMinutesPart ? utcMinutes : utcHours
        const parts = cronPart.split('/')
        return parts.length > 1 ? `${value}/${parts[1]}` : `${value}`
      } else {
        return cronPart
      }
    })
    .join(' ')
}

export function setTimeInRRule(rruleExpression: string, utcHours: number, utcMinutes: number) {
  const options = RRule.parseString(rruleExpression)
  options.byhour = [utcHours]
  options.byminute = [utcMinutes]
  const rule = new RRule(options)
  return rule.toString()
}

export function setStartDateInRRule(rruleExpression: string, startDate: Date) {
  const options = RRule.parseString(rruleExpression)
  options.dtstart = startDate
  const rule = new RRule(options)
  return rule.toString()
}

export function setInitialDateInRRule(expression: string) {
  if (expression && expression.includes('RRULE')) {
    const options = RRule.parseString(expression)
    if (!options.dtstart) {
      options.dtstart = new Date()
    }
    const rule = new RRule(options)
    return rule.toString()
  }
  return expression
}

export function setScheduleOptionsInRRule(rruleExpression: string, scheduleOptions: string) {
  const options = RRule.parseString(rruleExpression)
  if (scheduleOptions === 'monday_friday') {
    options.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
  } else if (scheduleOptions === 'weekends') {
    options.byweekday = [RRule.SA, RRule.SU]
  }
  const rule = new RRule(options)
  return rule.toString()
}

function isRRuleContainsDays(rruleExpression: string, arr: string[]) {
  const options = RRule.parseString(rruleExpression)
  if (Array.isArray(options.byweekday)) {
    if (options.byweekday.length === arr.length) {
      for (let i=0; i<arr.length; i++) {
        if (!arr.includes(options.byweekday[i].toString())) {
          return false
        }
      }
      return true
    }
  }
  return false
}

// based on schedule time decide if we should add or subtract (or maybe do nothing) to days depending on local timezone offset
const getDateDiff = (utcHours: number, utcMinutes: number) => {
  const today = new Date()
  today.setUTCHours(utcHours, utcMinutes, 0, 0)
  const offsetToday = new Date(today.getTime() + (today.getTimezoneOffset() * 60 * 1000))
  let dayDiff = 0
  if (moment(offsetToday).isBefore(moment(today), 'days')) {
    dayDiff = -1
  } else if (moment(offsetToday).isAfter(moment(today), 'days')) {
    dayDiff = 1
  }

  return dayDiff
}

/*
1. Based on cron expression check if there is any day shift between cron and local time (by checking timezone offset)
2. If there's no day shift - no changes
3. If there's day shift then adjust day parts of cron accordingly
    - for example if something should happen on 6pm UTC on Monday it is 3am JST on Tuesday
    - for easier presentation we need days to be in local time system's format
 */
const adjustCronDayPartsWithTime = (cronExpression: string, adjustToLocal = false) => {
  const adjustmentDirection = adjustToLocal ? -1 : 1
  const {utcHours, utcMinutes} = getHoursAndMinutesFromScheduleExpression(cronExpression)
  const dayDiff = getDateDiff(utcHours, utcMinutes) * adjustmentDirection
  if (dayDiff === 0) {
    // nothing to change
    return cronExpression
  }

  const [dayOfTheWeekPart, monthPart, dayOfTheMonthPart, ...rest] = cronExpression.split(' ').reverse()
  if (dayOfTheWeekPart !== '*') {
    const [days, nthWeek] = dayOfTheWeekPart.includes('L') ? dayOfTheWeekPart.split('L') : dayOfTheWeekPart.split('#')
    if (days === '*') {
      return cronExpression
    }

    let dayNumbers = days.split(',').map(day => parseInt(day, 10))

    // handle date range
    if (days.includes('-')) {
      const [startRange, endRange] = days.split('-')
        .map(day => parseInt(day, 10))
        .sort()
      dayNumbers = []
      for (let i = startRange; i <= endRange; i++) {
        dayNumbers.push(i)
      }
    }

    if (dayNumbers.length === 7) {
      // nothing to do, we have all days of the week
      return cronExpression
    }

    dayNumbers = dayNumbers.map(number => (number + 7 + dayDiff) % 7)

    let suffix = ''
    if (dayOfTheWeekPart.includes('L')) {
      suffix = 'L'
    } else if (nthWeek) {
      suffix = `#${nthWeek}`
    }

    const newCronExpression = [
      ...rest.reverse(),
      dayOfTheMonthPart,
      monthPart,
      `${dayNumbers.join(',')}${suffix}`,
    ].join(' ')
    return newCronExpression
  } else if (dayOfTheMonthPart.includes('/')) {
    const [days, interval] = dayOfTheMonthPart.split('/')
    // most likely lastDay is just 31
    const [firstDay, lastDay] = days.split('-')
    const adjustedFirstDay = (parseInt(firstDay, 10) + 31 + dayDiff) % 31

    const newCronExpression = [
      ...rest.reverse(),
      `${adjustedFirstDay}${lastDay ? `-${lastDay}` : ''}/${interval}`,
      monthPart,
      dayOfTheWeekPart,
    ].join(' ')
    return newCronExpression
  }

  return cronExpression
}

export const adjustCronToLocal = (cronExpression: string) => {
  return adjustCronDayPartsWithTime(cronExpression, true)
}

export const adjustCronToServer = (cronExpression: string) => {
  return adjustCronDayPartsWithTime(cronExpression, false)
}

/*
1. Based on cron expression check if there is any day shift between cron and local time (by checking timezone offset)
2. If there's no day shift - no changes
3. If there's day shift then adjust startDate accordingly
 */
const adjustCronStartDate = (startDate: Date, scheduleExpression: string, adjustToLocal = false) => {
  const {utcHours, utcMinutes} = getHoursAndMinutesFromScheduleExpression(scheduleExpression)
  if (adjustToLocal) {
    // set the date to start time adjusted by hours, it should convert to proper local time
    const startCopy = new Date(startDate.getTime())
    startCopy.setUTCHours(utcHours, utcMinutes, 0, 0)
    return startCopy
  }

  // adjusting to server time, set date to use current dates as UTC and adjust by dayDiff if needed
  const dayDiff = getDateDiff(utcHours, utcMinutes)
  const isStartOfTime = startDate.getUTCFullYear() < 1971
  const startCopy = isStartOfTime ? new Date() : new Date(startDate.getTime())
  startCopy.setUTCFullYear(
    startCopy.getFullYear(),
    startCopy.getMonth(),
    startCopy.getDate()
  )
  startCopy.setUTCHours(0, 0, 0, 0)

  if (dayDiff === 0) {
    return startCopy
  }

  // adjust startDate by 1 day
  const oneDay = 24 * 60 * 60 * 1000
  const newDate = new Date(startCopy.getTime() + (dayDiff * oneDay))
  return newDate
}

export const adjustCronStartDateToLocal = (startDate: Date, scheduleExpression: string) => {
  return adjustCronStartDate(startDate, scheduleExpression, true)
}

export const adjustCronStartDateToServer = (startDate: Date, scheduleExpression: string) => {
  return adjustCronStartDate(startDate, scheduleExpression, false)
}

export const prepareCronExpression = (
  date: Date,
  intervalType: string,
  repeatInterval: number,
  weekdays: number[],
  monthSelection: string,
  yearSelection: string,
  utcHours: number,
  utcMinutes: number
) => {
  const cronWeekdays = weekdays.length === 7 ? '*' : weekdays.sort().join(',')
  const cronInterval = repeatInterval > 1 ? `/${repeatInterval}` : ''
  const nthWeek = getPositionOfWeekdayInMonth(date)

  const timePart = setTimeInCron(`0 0 0`, utcHours, utcMinutes)
  if (intervalType === 'hour') {
    const hourInterval = repeatInterval > 0 ? `/${repeatInterval}` : ''
    return `${timePart}${hourInterval} * * *`
  }

  if (intervalType === 'day') {
    let day = '*'
    // if we have option "every X days" set day part of cron as
    //   `{first day of the month when it can happen}/{interval}`
    //    calculated as "startDate - N * interval"
    if (repeatInterval > 1) {
      let initialDayInAMonth = date.getDate()
      while ((initialDayInAMonth - repeatInterval) >= 1) {
        initialDayInAMonth -= repeatInterval
      }
      // for example "3-31"
      day = `${initialDayInAMonth}-31`
    }
    return `${timePart} ${day}${cronInterval} * *`
  }

  if (intervalType === 'week') {
    return `${timePart} * * ${cronWeekdays}`
  }

  if (intervalType === 'month') {
    switch (monthSelection) {
      case 'last':
        return `${timePart} * *${cronInterval} ${date.getDay()}L`
      case 'nth':
        return `${timePart} * *${cronInterval} ${date.getDay()}#${nthWeek}`
      default:
        return `${timePart} ${date.getDate()} *${cronInterval} *`
    }
  }

  if (intervalType === 'year') {
    switch (yearSelection) {
      case 'last':
        return `${timePart} * ${date.getMonth() + 1}${cronInterval} ${date.getDay()}L`
      case 'nth':
        return `${timePart} * ${date.getMonth() + 1}${cronInterval} ${date.getDay()}#${nthWeek}`
      default:
        return `${timePart} ${date.getDate()} ${date.getMonth() + 1}${cronInterval} *`
    }
  }

  return `${timePart} * * *`
}

function getRRuleFrequencyByIntervalType(type: string): Frequency {
  let freq = RRule.DAILY
  if (type === 'hour') {
    freq = RRule.HOURLY
  } else if (type === 'week') {
    freq = RRule.WEEKLY
  } else if (type === 'month') {
    freq = RRule.MONTHLY
  }
  return freq
}

function matchWeekDays(day: number): number {
  if (day === 0) return 6
  return day-1
}

export const prepareRRuleExpression = (
  date: Date,
  intervalType: string,
  repeatInterval: number,
  weekdays: number[],
  monthSelection: string,
  yearSelection: string,
  utcHours: number,
  utcMinutes: number
) => {
  const rule = new RRule({
    wkst: RRule.MO,
    freq: getRRuleFrequencyByIntervalType(intervalType),
    interval: repeatInterval,
    dtstart: date,
    byhour: [utcHours],
    byminute: [utcMinutes],
  })

  // RRule byweekday
  if ((intervalType === 'day' && repeatInterval == 1) || intervalType === 'week') {
    rule.origOptions.byweekday = weekdays.map((d: number) => matchWeekDays(d))
  } else {
    rule.origOptions.byweekday = []
  }

  // Month Selection
  const nthWeek = getPositionOfWeekdayInMonth(date)
  if (intervalType === 'month') {
    if (monthSelection === 'day') {
      rule.origOptions.bymonthday = date.getDate()
    } else if (monthSelection === 'last') {
      rule.origOptions.byweekday = [matchWeekDays(date.getDay())]
      rule.origOptions.bysetpos = -1
    } else if (monthSelection === 'nth') {
      rule.origOptions.byweekday = getNthWeekdayRRule(date, nthWeek)
    }
  }
  console.log(rule.toString())
  return rule.toString()
}

const getScheduleTranslationKeyAndParamsCron = (cronExpression: string, weeklyIntervals: number) => {
  const momentLocale = moment().localeData()
  const params: {[field: string]: string | number} = {}
  let title = ''

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [seconds, minutes, hours, days, months, daysOfWeek] = cronExpression.split(' ')
  const repeatInterval = cronExpression.includes('/') ?
    parseInt(cronExpression.split('/')[1].split(' ')[0], 10) :
    weeklyIntervals
  const isNthDayOfWeek = daysOfWeek ? daysOfWeek.includes('#') : false
  const weekdaysFromCron = getDatesFromCron(cronExpression)
  const weekdaysStrings = momentLocale.weekdays()
  const weekdays: string[] = []
  for (const weekdayIndex of weekdaysOrder) {
    if (weekdaysFromCron.includes(weekdayIndex)) {
      weekdays.push(weekdaysStrings[weekdayIndex])
    }
  }

  // don't use 0!
  params.count = repeatInterval === 0 ? 1 : repeatInterval
  params.day = parseInt((days.match(/^\d+/) || ['1']).join(''), 10)
  params.weekday = weekdays.join(', ')
  // @ts-ignore
  params.nth = momentLocale.ordinal(isNthDayOfWeek ? parseInt(daysOfWeek.split('#')[1], 10) : 1, 'D')
  params.month = momentLocale.months()[Math.min(11, parseInt((months.match(/^\d+/) || ['1']).join(''), 10) - 1)]

  // don't use 0!
  const skipIntervalUse = weeklyIntervals === 1 || weeklyIntervals === 0
  if (hours === '*' || hours.includes('/')) {
    title = 'Nav_Schedule settings repeat info hour'
  } else if (skipIntervalUse && (
    ((days.startsWith('*') || days.includes('/')) && months === '*' && daysOfWeek === '*') ||
    daysOfWeek === '0-6' ||
    daysOfWeek.split(',').length === 7
  )) {
    title = 'Nav_Schedule settings repeat info every_day'
  } else if (skipIntervalUse && days === '*' && months === '*' && (daysOfWeek === '1-5' || daysOfWeek === '1,2,3,4,5')) {
    title = 'Nav_Schedule settings repeat info monday_friday'
  } else if (skipIntervalUse && days === '*' && months === '*' && daysOfWeek === '0,6') {
    title = 'Nav_Schedule settings repeat info weekends'
  } else if (days === '*' && months === '*' && !daysOfWeek.toUpperCase().endsWith('L') && !daysOfWeek.includes('#')) {
    title = 'Nav_Schedule settings repeat info week'
  } else if (months.startsWith('*')) {
    if (daysOfWeek.toUpperCase().endsWith('L')) {
      title = 'Nav_Schedule settings repeat info month last'
    } else if (daysOfWeek.includes('#')) {
      title = 'Nav_Schedule settings repeat info month nth day'
    } else {
      title = 'Nav_Schedule settings repeat info month day'
    }
  } else {
    if (daysOfWeek.toUpperCase().endsWith('L')) {
      title = 'Nav_Schedule settings repeat info year last'
    } else if (daysOfWeek.includes('#')) {
      title = 'Nav_Schedule settings repeat info year nth day'
    } else {
      title = 'Nav_Schedule settings repeat info year month'
    }
  }

  return {
    title,
    params,
  }
}

const getScheduleTranslationKeyAndParamsRRule = (rruleExpression: string, weeklyIntervals: number) => {
  const momentLocale = moment().localeData()
  const params: {[field: string]: string | number} = {}
  let title = ''

  const repeatInterval = getIntervalFromRRule(rruleExpression, weeklyIntervals)
  const intrevalType = getRadioSelectionFromRRule(rruleExpression, 'day')
  const isNthDayOfWeek = intrevalType === 'nth' ? true : false
  const weekdaysFromRRule = getDatesFromRRule(rruleExpression)
  const weekdaysStrings = momentLocale.weekdays()
  const fullWeekDay = 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday'
  const weekdays: string[] = []
  for (const weekdayIndex of weekdaysOrder) {
    if (weekdaysFromRRule.includes(weekdayIndex)) {
      weekdays.push(weekdaysStrings[weekdayIndex])
    }
  }
  const nthWD = getNthWeekdayFromRRule(rruleExpression) || 1

  params.count = repeatInterval || 1
  params.day = getDayInMonthFromRRule(rruleExpression) || 1
  params.weekday = weekdays.join(', ')
  // @ts-ignore
  params.nth = momentLocale.ordinal(isNthDayOfWeek ? nthWD : 1, 'D')
  params.month = momentLocale.months()[Math.min(11, getMonthFromRRule(rruleExpression) - 1)]

  if (getInitialRRuleIntervalType(rruleExpression) === 'hour') {
    title = 'Nav_Schedule settings repeat info hour'
  } else if (isRRuleContainsDays(rruleExpression, ['MO', 'TU', 'WE', 'TH', 'FR'])) {
    title = 'Nav_Schedule settings repeat info monday_friday'
  } else if (isRRuleContainsDays(rruleExpression, ['SA', 'SU'])) {
    if (getInitialRRuleIntervalType(rruleExpression) === 'week') {
      title = 'Nav_Schedule settings repeat info week'
    }
    title = 'Nav_Schedule settings repeat info weekends'
  } else if (getInitialRRuleIntervalType(rruleExpression) === 'day') {
    title = 'Nav_Schedule settings repeat info every_day'
    params.weekday = fullWeekDay
  } else if (getInitialRRuleIntervalType(rruleExpression) === 'week') {
    title = 'Nav_Schedule settings repeat info week'
  } else if (getInitialRRuleIntervalType(rruleExpression) === 'month') {
    if (intrevalType === 'last') {
      title = 'Nav_Schedule settings repeat info month last'
    } else if (intrevalType === 'nth') {
      title = 'Nav_Schedule settings repeat info month nth day'
    } else {
      title = 'Nav_Schedule settings repeat info month day'
      params.weekday = fullWeekDay
    }
  } else if (getInitialRRuleIntervalType(rruleExpression) === 'year') {
    if (intrevalType === 'last') {
      title = 'Nav_Schedule settings repeat info year last'
    } else if (intrevalType === 'nth') {
      title = 'Nav_Schedule settings repeat info year nth day'
    } else {
      title = 'Nav_Schedule settings repeat info year day'
      params.weekday = fullWeekDay
    }
  }

  return {
    title: title,
    params: params,
  }
}

export const getScheduleTranslationKeyAndParams = (expression: string, weeklyIntervals: number) => {
  return expression && expression.includes('RRULE') ?
    getScheduleTranslationKeyAndParamsRRule(expression, weeklyIntervals) :
    getScheduleTranslationKeyAndParamsCron(expression, weeklyIntervals)
}

const getHoursAndMinutesFromCronExpression = (cronExpression?: string): Date => {
  const now = new Date()
  if (cronExpression) {
    // eslint-disable-next-line no-unused-vars
    const [_, minutesPart, hoursPart] = cronExpression.split(' ')
    const minutes = parseInt(minutesPart.split('/')[0], 10)
    // this can be a star
    const hours = hoursPart.startsWith('*') ?
      ((now.getUTCHours() + 1) % 24) :
      parseInt(hoursPart.split('/')[0], 10)
    now.setUTCHours(hours, minutes, 0, 0)
  }
  return now
}

const getHoursAndMinutesFromRRuleExpression = (rruleExpression?: string): Date => {
  const now = new Date()
  if (rruleExpression) {
    const options = RRule.parseString(rruleExpression)
    let hours: any = now.getHours()
    let minutes: any = now.getMinutes()
    if (options.byhour) {
      hours = options.byhour
    }
    if (options.byminute) {
      minutes = options.byminute
    }
    now.setUTCHours(hours, minutes, 0, 0)
  }
  return now
}

export const getHoursAndMinutesFromScheduleExpression = (expression: string) => {
  const now = executeExpression(
    expression,
    getHoursAndMinutesFromRRuleExpression,
    getHoursAndMinutesFromCronExpression
  )
  return {
    hours: now.getHours(),
    minutes: now.getMinutes(),
    utcHours: now.getUTCHours(),
    utcMinutes: now.getUTCMinutes(),
  }
}


export const getNewScheduleSorting = (
  schedules: ScheduleListElement[],
  initialIndex: number,
  finalIndex: number
) => schedules.map((item, index) => {
  let newIndex = index
  if (initialIndex > finalIndex && index >= finalIndex && index < initialIndex) {
    newIndex = index + 1
  } else if (initialIndex < finalIndex && index > initialIndex && index <= finalIndex) {
    newIndex = index - 1
  } else if (index === initialIndex) {
    newIndex = finalIndex
  }

  return {id: item.id, newIndex}
})

